
import { Vue, Options } from "vue-class-component";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { AwbDetailData, CargoHistoryNgen } from "@/domain/entities/NGen";
import { TrackingCargoHistoryNgenApiRequest } from "@/data/payload/api/NGenApiRequest";
import { gtmCargoData } from "@/app/ui/views/cargo/modules/gtm";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { AirportData } from "@/domain/entities/Cargo";

@Options({})
export default class CargoFlightManualSection extends Vue {
  unmounted() {
    NGenController.setErrorAwb("");
    this.fillFormBookingFromCache();
  }

  convertProduct(name: string) {
    return name.toLowerCase().includes("express") ? "Express" : "REGULAR";
  }
  setDateRange(date: Array<any>) {
    CargoController.setBookingDate(date);
  }
  showPopupFailedCheckAwb() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.$t("Nomor Kargo Tidak Ditemukan"),
        image: "awb-not-found",
        message:
          this.$t("Cek kembali nomor kargo Anda atau lakukan Booking Kargo secara manual"),
        textSuccess: this.$t("Isi Manual"),
        textCancel: this.$t("Cek Kembali"),
        onSubmit: () => {
          // GTM
          const gtmDetailViewInputManual = gtmCargoData({
            type: this.formBooking.cargoType
          }).detailViewInputManual;
          dataLayer(
            gtmDetailViewInputManual.eventName,
            gtmDetailViewInputManual.property,
            []
          );
          this.fillFormBookingFromCache();
          CargoController.setFillForm(true);
          NGenController.setErrorAwb("error");
          MainAppController.closeMessageModal();
        },
        onClose: () => {
          CargoController.setFillForm(false);
          MainAppController.closeMessageModal();
          NGenController.setErrorAwb("");
        }
      })
    );
  }
  formBookingCache = JSON.parse(JSON.stringify(this.formBooking));
  get formBooking() {
    return CargoController.form;
  }
  fillFormBookingFromCache() {
    this.formBooking["airportOrigin"] = this.formBookingCache.airportOrigin;
    this.formBooking[
      "airportDestination"
    ] = this.formBookingCache.airportDestination;
    this.formBooking[
      "cargoCommodityCode"
    ] = this.formBookingCache.cargoCommodityCode;
    this.formBooking[
      "cargoCommodityName"
    ] = this.formBookingCache.cargoCommodityName;
    this.formBooking["cargoProduct"] = this.formBookingCache.cargoProduct;
    this.formBooking["vehicleNumber"] = this.formBookingCache.vehicleNumber;
    this.formBooking[
      "estTotalCargoGrossWeight"
    ] = this.formBookingCache.estTotalCargoGrossWeight;
    this.formBooking[
      "actualTotalCargoGrossWeight"
    ] = this.formBookingCache.actualTotalCargoGrossWeight;
    this.formBooking[
      "estTotalCargoVolumeWeight"
    ] = this.formBookingCache.estTotalCargoVolumeWeight;
    this.formBooking[
      "actualTotalCargoVolumeWeight"
    ] = this.formBookingCache.actualTotalCargoVolumeWeight;
  }
  get disabledCheckAwb() {
    return !this.formBooking.cargoNumber;
  }
  onMappingAirport(data: AirportData[]): any[] {
    return data.map((airport: AirportData) => ({
      ...airport,
      name: `${airport.airportCode} - ${airport.airportName}`,
      value: airport.airportCode
    }));
  }
  get optionAirportOrigin() {
    return this.onMappingAirport(CargoController.airportDataOrigin.data);
  }
  get optionAirportDestination() {
    return this.onMappingAirport(CargoController.airportDataDestination.data);
  }
  get errorAwb() {
    return NGenController.errorAwb;
  }
  async onCheckAwb() {
    MainAppController.showLoading();
    NGenController.setAwbDetailData(new AwbDetailData());
    NGenController.setErrorAwb("");
    try {
      const responseAwbDetailData = await NGenController.checkAwbToNgen({
        payload: new TrackingCargoHistoryNgenApiRequest(
          this.formBooking.cargoNumber
        )
      });
      if (responseAwbDetailData.errorAwb === "AWB does not exist") {
        this.showPopupFailedCheckAwb();
      } else {
        // GTM Awb Valid
        const gtmAwbValid = gtmCargoData({
          type: this.formBooking.cargoType
        }).awbValid;
        dataLayer(gtmAwbValid.eventName, gtmAwbValid.property, []);

        const tracking = responseAwbDetailData.awbTracking
          .filter((e: CargoHistoryNgen) => e.status === "Booking confirmed")
          .sort(
            (a: CargoHistoryNgen, b: CargoHistoryNgen) =>
              new Date(`${a.flightDate} ${a.etd}`).getTime() -
              new Date(`${b.flightDate} ${b.etd}`).getTime()
          );
        const departureDate = new Date(
          `${tracking[0].flightDate} ${tracking[0].etd}`
        );
        const arrivalDate = new Date(
          `${tracking[tracking.length - 1].flightDate} ${
            tracking[tracking.length - 1].eta
          }`
        );
        this.setDateRange([departureDate, arrivalDate]);
        this.formBooking["ngenCreatedAt"] =
          responseAwbDetailData.awbDetails.awbDate;

        const commodity = responseAwbDetailData.awbDetails.commodity.split("-");
        this.formBooking["airportOrigin"] = this.optionAirportOrigin[0];
        this.formBooking[
          "airportDestination"
        ] = this.optionAirportDestination[0];
        this.formBooking["cargoCommodityCode"] = commodity[0];
        this.formBooking["cargoCommodityName"] = commodity[1];
        this.formBooking["cargoProduct"] = this.convertProduct(
          responseAwbDetailData.awbDetails.product
        );
        this.formBooking["cargoNumber"] =
          responseAwbDetailData.awbDetails.awbNumber;
        this.formBooking["vehicleNumber"] =
          tracking[tracking.length - 1].flightNumber;
        this.formBooking["estTotalCargoGrossWeight"] =
          responseAwbDetailData.awbDetails.grossWeight;
        this.formBooking["actualTotalCargoGrossWeight"] =
          responseAwbDetailData.awbDetails.grossWeight;
        this.formBooking["estTotalCargoVolumeWeight"] =
          responseAwbDetailData.awbDetails.volumeWeight;
        this.formBooking["actualTotalCargoVolumeWeight"] =
          responseAwbDetailData.awbDetails.volumeWeight;
        this.formBooking.cargoNumber = "";
        this.formBooking.shcCode = "";
        this.formBooking.shcName = "";

        // GTM
        const gtmDetailViewNgen = gtmCargoData({
          type: this.formBooking.cargoType
        }).detailViewNgen;
        dataLayer(gtmDetailViewNgen.eventName, gtmDetailViewNgen.property, []);
        CargoController.setFillForm(true);
        NGenController.setErrorAwb("");
      }
    } catch (err) {
      // GTM Awb Invalid
      const gtmAwbInvalid = gtmCargoData({
        type: this.formBooking.cargoType
      }).awbInvalid;
      dataLayer(gtmAwbInvalid.eventName, gtmAwbInvalid.property, []);
      this.showPopupFailedCheckAwb();
    } finally {
      MainAppController.closeLoading();
    }
  }

  updateFormBookingCargoNumber(newValue: string) {
    CargoController.setUpdateFromBookingCargoNumber(newValue);
  }
}
